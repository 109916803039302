import axios from 'axios'
import Vue from 'vue'
import * as dotenv from 'dotenv'
import router from '@/router'
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'
import { Notification } from 'element-ui'

dotenv.config({ path: '../.env' })

let calls = 0
const instance = axios.create()

// axios defaults
instance.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
instance.defaults.baseURL = process.env.VUE_APP_API_URL
instance.defaults.headers.post['Content-Type'] = 'application/json'

// Interceptors to send the JWT with each request, if it exists
instance.interceptors.request.use(
  (config) => {
    calls++
    nprogress.start()
    const token = Vue.$cookies.get('authToken')
    if (token != null) {
      if (typeof config.headers !== 'undefined') {
        config.headers.Authorization = `Bearer ${token}`
      }
    }

    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)

instance.interceptors.response.use(
  (response) => {
    calls--

    if (calls == 0) {
      nprogress.done()
    }

    if (response.data?.notification?.message) {
      Notification({
        type: response.data?.notification?.type ?? 'info',
        title: response.data?.notification?.title ?? 'Info',
        message: response.data?.notification?.message,
      })
    }

    // Controle de version
    if (response.data?.version && response.data?.version !== process.env.VUE_APP_VERSION) {
      Notification({
        type: 'info',
        title: 'Nouvelle version',
        message:
          'Une nouvelle version est disponible, veuillez recharger votre navigateur (' + response.data?.version + ')',
      })
    }

    return response
  },
  (error) => {
    calls--
    if (calls == 0) {
      nprogress.done()
    }
    if (
      error.response?.status === 401 &&
      (error.response?.data?.message === 'Your JWT token is expired.' ||
        error.response?.data?.message === 'Your JWT token is invalid.' ||
        error.response?.data?.message === 'Your JWT token is missing.')
    ) {
      Vue.$cookies.remove('authToken')
      const url = router.currentRoute.fullPath

      router.push({ name: 'login', query: { returnURL: url } })
    } else {
      if (error.response?.data?.notification) {
        const message = error.response?.data?.notification?.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.'
        Notification({
          type: error.response?.data?.notification?.type ?? 'error',
          title: error.response?.data?.notification?.title ?? 'Erreur',
          message: message,
        })
      }

      // Déconnexion par le serveur
      if (error.response.data?.data?.action && error.response.data.data.action == "logout") {
        // eslint-disable-next-line no-console
        console.log('Error : Disconnected by server')
        // Notification({
        //   type: 'warning',
        //   title: 'Vous avez été déconnecté',
        //   message: 'Vous vous êtes connecté depuis un autre appareil. Merci de vous identifier à nouveau sur cet appareil pour reprendre la session.',
        // })
        Vue.$cookies.remove('authToken')
        const url = router.currentRoute.fullPath
        router.push({ name: 'login', query: { returnURL: url } })
      }
      return Promise.reject(error)
    }
  }
)

export default instance
