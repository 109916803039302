





















































































































































































import Vue from 'vue'
import { PurchaseOrder, Project, Sale, ApiResponse, Contact, RequisitionItem } from '../../interfaces'
import DateTimeFormatOptions = Intl.DateTimeFormatOptions
import ContactForm from '@/components/ContactForm.vue'

interface DropdownAction {
  action: string
  purchaseOrder?: PurchaseOrder
  index: number
}

interface ExistingList {
  list?: string
}

export default Vue.extend({
  name: 'PurchaseOrders',
  components: { ContactForm },
  props: {
    sale: {
      type: Object as () => Sale,
      required: false,
      default: null,
    },
    saleItems: {
      type: Array,
      required: false,
      default: [] as RequisitionItem[],
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
    fetchTrigger: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      busy: false,
      purchaseOrders: [] as PurchaseOrder[],
      activePurchaseOrder: {} as PurchaseOrder,
      showPurchaseOrderModal: false,
      acquereurs: [] as Record<string, string | null>[],
      contactList: [] as Contact[],
      acquereursPhoneNumbers: [] as Record<string, string | null>[],
      saleItemsSearchList: [] as Record<string, string>[],
      lastPurchaseOrder: {} as PurchaseOrder,
      existingLists: [] as Record<string, string>[],
      selectedList: null as string | null,
      itemNumber: null as string | null,
      showAddContactModal: false,
      newContactType: null as string | null,
      newContactId: null as string | null,
      infoPrefill: null as string | null,
      newContact: null as Contact | null,
      resetContactForm: false,
      acquereurId: null as string | null,
    }
  },
  computed: {
    isFormDisabled(): boolean {
      return !this.itemNumber
    },
  },
  watch: {
    sale(newVal) {
      if (Number.isInteger(newVal.id) && this.activeTab === 'purchaseOrders') {
        this.fetchPurchaseOrders()
        this.activePurchaseOrder.sale = newVal
        this.$emit('fetchSaleItems', newVal.id)
      }
    },
    activeTab(newVal) {
      if (newVal === 'purchaseOrders' && this.sale.id) {
        this.fetchPurchaseOrders()
        this.$emit('fetchSaleItems', this.sale.id)
        this.activePurchaseOrder.sale = this.sale
      }
    },
    saleItems(newVal) {
      if (newVal) {
        newVal.forEach((item: RequisitionItem) => {
          const element = this.saleItemsSearchList.filter((str: Record<string, string>) => {
            return str.value === item.fullItemNumber
          })
          if (element.length === 0) {
            const formattedDescription =
              item?.description && item?.description?.length > 50
                ? `${item?.description?.substring(0, 50)}...`
                : item.description
            this.saleItemsSearchList.push({
              value: item.fullItemNumber ?? '',
              label: `${item.fullItemNumber} - ${formattedDescription}`,
            })
          }
        })
      }
    },
    newContact(newVal) {
      if (newVal !== null) {
        switch (this.newContactType) {
          case 'acquereur':
            this.updateAcquereur(newVal)
            break
        }
        this.newContact = null
        this.newContactType = ''
      }
    },
    fetchTrigger() {
      this.fetchPurchaseOrders()
    },
  },
  mounted() {
    if (Number.isInteger(this.sale.id) && this.activeTab === 'purchaseOrders') {
      this.activePurchaseOrder.sale = this.sale
      this.fetchPurchaseOrders()
      this.$emit('fetchSaleItems', this.sale.id)
    }
  },
  methods: {
    formatCurrency(number: number): string {
      return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number).toString()
    },
    formatDescription(purchaseOrder: PurchaseOrder): string {
      if (purchaseOrder) {
        const str = purchaseOrder.requisitionItem?.description ?? ''
        return str.length > 100 ? `${str.substring(0, 100).trim()}...` : str
      }
      return ''
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        const dateOptions = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        } as DateTimeFormatOptions
        return new Intl.DateTimeFormat('fr-FR', dateOptions).format(date)
      } else {
        return '-'
      }
    },
    formatAcquereurName(purchaseOrder: PurchaseOrder, emptyIfUndefined = false) {
      const emptyName = !emptyIfUndefined ? '-' : ''
      return purchaseOrder.acquereur ? purchaseOrder.acquereur?.formName : emptyName
    },
    formatAcquereurPhone(purchaseOrder: PurchaseOrder) {
      if (purchaseOrder.mustCall && purchaseOrder.acquereurPhoneNumber) {
        return purchaseOrder.acquereurPhoneNumber ?? '-'
      }

      return '-'
    },
    fetchPurchaseOrders() {
      if (!this.busy) {
        this.busy = true

        this.$api
          .get(`/purchase-order/list/${this.sale?.id}`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.purchaseOrders = apiResponse.data.purchaseOrders
            if (apiResponse.data.lastPlateNumber && apiResponse.data.lastAcquereur) {
              this.lastPurchaseOrder = {
                plateNumber: apiResponse.data.lastPlateNumber,
                acquereur: apiResponse.data.lastAcquereur,
                acquereurPhoneNumber: apiResponse.data.lastAcquereurPhoneNumber,
              }
            }
          })
          .finally(() => {
            this.busy = false
            this.fetchPurchaseOrderSavedList()
          })
      }
    },
    fetchPurchaseOrderSavedList() {
      if (!this.busy) {
        this.$api
          .get(`/purchase-order/${this.sale?.id}/saved-lists`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            if (apiResponse.data.length > 0) {
              this.existingLists = []
              apiResponse.data.forEach((existingList: ExistingList) => {
                this.existingLists.push({
                  value: existingList.list ?? '',
                })
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    savePurchaseOrder() {
      const data = {
        ...this.activePurchaseOrder,
        sale: this.sale.id,
        acquereur: this.getLastAcquereur()?.value,
        itemNumber: this.itemNumber,
      }
      let success = false
      this.setLastPurchaseOrder()
      this.$api
        .post('/purchase-order', data)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          const lastPurchaseOrder = apiResponse.data

          this.purchaseOrders.unshift(apiResponse.data)

          if (apiResponse.data) {
            this.lastPurchaseOrder = {
              plateNumber: lastPurchaseOrder.plateNumber,
              acquereur: lastPurchaseOrder.acquereur,
              acquereurPhoneNumber: lastPurchaseOrder.acquereurPhoneNumber,
            }
            if (this.filterExistingList(apiResponse.data.list, true)?.length === 0) {
              this.existingLists.push({
                value: apiResponse.data.list,
              })
            }
          }
          this.$notify({
            type: 'success',
            title: 'Succès',
            message: `Ordre d'achat créé avec succès !`,
          })
          success = true
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
            if (apiResponse.data.lastPlateNumber && apiResponse.data.lastAcquereur) {
              this.lastPurchaseOrder = {
                plateNumber: apiResponse.data.lastPlateNumber,
                acquereur: apiResponse.data.lastAcquereur,
                acquereurPhoneNumber: apiResponse.data.lastAcquereurPhoneNumber,
              }
            }
          }
        })
        .finally(() => {
          this.busy = false
          if (success) {
            this.resetPurchaseOrderModal()
          }
        })
    },
    updatePurchaseOrder() {
      let data = {
        ...this.activePurchaseOrder,
        sale: this.sale.id,
        itemNumber: this.itemNumber,
      } as Record<string, string | number | boolean | null>

      if (this.acquereurId) {
        data.acquereur = this.acquereurId
      } else {
        data.acquereur = null
      }

      let success = false
      this.$api
        .put(`/purchase-order/${this.activePurchaseOrder.id}`, data)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          const index = this.searchPurchaseOrder(this.activePurchaseOrder.id) ?? -1
          if (index > -1) {
            this.purchaseOrders.splice(index, 1, apiResponse.data.updatedPurchaseOrder)
            if (apiResponse.data.lastPlateNumber && apiResponse.data.lastAcquereur) {
              this.lastPurchaseOrder = {
                plateNumber: apiResponse.data.lastPlateNumber,
                acquereur: apiResponse.data.lastAcquereur,
                acquereurPhoneNumber: apiResponse.data.lastAcquereurPhoneNumber,
              }
            }
            if (apiResponse.data.list && this.filterExistingList(apiResponse.data.list, true)?.length === 0) {
              this.existingLists.push({
                value: apiResponse.data.list,
              })
            }
          } else {
            this.purchaseOrders = []
            this.fetchPurchaseOrders()
          }
          this.$notify({
            type: 'success',
            title: 'Succès',
            message: `Ordre d'achat modifié avec succès !`,
          })
          success = true
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          if (success) {
            this.closeEditPurchaseOrderModal()
          }
        })
    },
    deletePurchaseOrder(purchaseOrder: PurchaseOrder) {
      if (!this.busy) {
        this.$confirm(
          "Êtes-vous sûr(e) de vouloir supprimer cet ordre d'achat ? Attention, cette opération est irréversible.",
          'Confirmation',
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
            type: 'warning',
          }
        ).then(() => {
          this.busy = true

          this.$api
            .delete(`/purchase-order/${purchaseOrder?.id}`)
            .then(() => {
              const index = this.searchPurchaseOrder(purchaseOrder?.id)
              if (index > -1) {
                this.purchaseOrders.splice(index, 1)
              } else {
                this.purchaseOrders = []
                this.fetchPurchaseOrders()
              }
              this.$notify({
                type: 'success',
                title: 'Succès',
                message: `Ordre d'achat supprimé avec succès.`,
              })
            })
            .catch(() => {
              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  'Une erreur est survenue lors de la suppression. Veuillez nous excuser pour la gêne occasionnée.',
              })
            })
            .finally(() => {
              this.busy = false
              this.fetchPurchaseOrderSavedList()
            })
        })
      }
    },
    filterSearchSaleItemsList(search: string) {
      return search
        ? this.saleItemsSearchList?.filter((str: Record<string, string>) => str.value === search ?? null)
        : this.saleItemsSearchList
    },
    searchSaleItem(search: string, cb: CallableFunction) {
      const results = this.filterSearchSaleItemsList(search)
      cb(results)
    },
    selectSaleItem() {
      const filteredSaleItemsList = this.filterSearchSaleItemsList(
        this.activePurchaseOrder.requisitionItem?.fullItemNumber ?? ''
      )
      if (filteredSaleItemsList.length === 1) {
        this.itemNumber = filteredSaleItemsList.pop()?.value ?? null
      } else {
        this.itemNumber = ''
      }
    },
    filterExistingList(search: string, returnEmpty = false) {
      const returnEmptyValue = returnEmpty ? [] : this.existingLists
      return search
        ? this.existingLists?.filter(
          (str: Record<string, string>) => str.value?.toLowerCase().indexOf(search.toLowerCase()) === 0 ?? null
        )
        : returnEmptyValue
    },
    searchExistingList(search: string, cb: CallableFunction) {
      const results = this.filterExistingList(search)
      cb(results)
    },
    printPurchaseOrdersList() {
      if (this.selectedList) {
        this.downloadPurchaseOrderPrint(this.selectedList)
      } else {
        this.downloadPurchaseOrderPrint()
      }
    },
    downloadPurchaseOrderPrint(list = '') {
      this.$api
        .post(`/purchase-order/print`, {
          list,
          sale: this.sale?.id,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          // const documentUrl = `${process.env.VUE_APP_API_URL}/purchase-order/${apiResponse.data}/download`
          // window.open(documentUrl, '_blank')
          if (apiResponse.success === true) {
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/${apiResponse.data.filename}/download_once?contentd=inline`
            window.open(documentUrl, '_blank')
          }
        })
    },
    handlePurchaseOrderCommand(command: DropdownAction) {
      // Edit requisition item
      if (command.action == 'edit' && command.purchaseOrder) {
        this.openEditPurchaseOrder(command.purchaseOrder)
      }
      if (command.action == 'delete' && command.purchaseOrder) {
        this.deletePurchaseOrder(command.purchaseOrder)
      }
    },
    setLastPurchaseOrder() {
      this.lastPurchaseOrder = {
        plateNumber: this.activePurchaseOrder.plateNumber,
        acquereur: this.getLastAcquereur(),
        acquereurPhoneNumber: this.activePurchaseOrder.acquereurPhoneNumber,
      }
    },
    openEditPurchaseOrder(purchaseOrder?: PurchaseOrder) {
      // Open modal
      this.showPurchaseOrderModal = true
      if (purchaseOrder) {
        this.activePurchaseOrder = {
          ...purchaseOrder,
        }
        this.itemNumber = this.activePurchaseOrder.requisitionItem?.fullItemNumber ?? null
        if (this.activePurchaseOrder.acquereur) {
          this.acquereurs = [
            {
              label: this.activePurchaseOrder.acquereur?.formName ?? null,
              value: this.activePurchaseOrder.acquereur?.id?.toString() ?? null,
            },
          ]
        } else {
          this.acquereurs = []
        }
        this.acquereurId = this.activePurchaseOrder.acquereur?.id?.toString() ?? null
      } else {
        this.activePurchaseOrder = {
          ...this.lastPurchaseOrder,
          mustCall: false,
        }
        if (this.lastPurchaseOrder.acquereur) {
          this.acquereurs = [
            {
              label: this.lastPurchaseOrder.acquereur?.formName ?? null,
              value: this.lastPurchaseOrder.acquereur?.id?.toString() ?? null,
            },
          ]
        }
        this.acquereurId = this.activePurchaseOrder.acquereur?.id?.toString() ?? null
      }
      this.activePurchaseOrder.sale = this.sale
    },
    closeEditPurchaseOrderModal() {
      this.showPurchaseOrderModal = false
      this.activePurchaseOrder = {}
      this.acquereurs = []
    },
    resetPurchaseOrderModal() {
      this.activePurchaseOrder = {
        ...this.activePurchaseOrder,
        mustCall: false,
      }
      delete this.activePurchaseOrder.id
      delete this.activePurchaseOrder.amount
      // delete this.activePurchaseOrder.itemNumber
      delete this.activePurchaseOrder.list

      this.itemNumber = null
    },
    searchPurchaseOrder(purchaseOrderId?: number | null) {
      if (!purchaseOrderId) return -1
      return this.purchaseOrders.findIndex((purchaseOrder: PurchaseOrder) => purchaseOrder?.id === purchaseOrderId)
    },
    goToRequisitionItem(row: PurchaseOrder): void {
      const requisitionItem = row.requisitionItem ?? null
      if (requisitionItem) {
        const project = requisitionItem.requisition?.project as Project
        if (project) {
          this.$router.push({
            name: 'requisition_item_edit',
            params: {
              itemId: requisitionItem.id?.toString() ?? '',
            },
            query: {
              backTo: this.$route.name,
            },
          })
        }
      }
    },
    searchAcquereurs(search?: string) {
      if (search && search.length >= 2) {
        this.acquereurs = this.searchContacts(search)
      }
    },
    searchContacts(search?: string): Record<string, string | null>[] {
      this.busy = true
      const acquereurs = [] as Record<string, string | null>[]

      if (search) {
        this.infoPrefill = search
      }

      this.acquereursPhoneNumbers = []
      this.$api
        .get('/contacts', {
          params: {
            search: search ?? null,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          if (apiResponse.data && Array.isArray(apiResponse.data)) {
            this.contactList = apiResponse.data
            apiResponse.data.forEach((contact: Contact) => {
              let contactLabel = contact.formName
              if (contact.city) {
                  contactLabel = `${contact.formName} - ${contact.city}`
              }
              acquereurs.push({
                label: contactLabel ?? null,
                value: contact.id?.toString() ?? null,
              })
              const phoneNumber = contact.mobile ?? contact.phone
              this.acquereursPhoneNumbers.push({
                label: phoneNumber ?? null,
                value: contact.id?.toString() ?? null,
              })
            })
          }
        })
        .finally(() => {
          this.busy = false
        })
      return acquereurs
    },
    changeAcquereur(id: string, type: string) {
      if (id === 'add') {
        this.openContactForm(type)
      } else {
        this.activePurchaseOrder = {
          ...this.activePurchaseOrder,
          acquereur: this.contactList.find((contact: Contact) => contact.id?.toString() === id),
          acquereurPhoneNumber: this.acquereursPhoneNumbers.find((acquereur) => acquereur.value === id)?.label ?? '',
          plateNumber: null,
        }
      }
    },
    clearNewContact() {
      this.acquereurs = []
      this.activePurchaseOrder.acquereur = null
      this.newContactId = null
      this.infoPrefill = null
    },
    openContactForm(type: string, contactId?: string) {
      this.showAddContactModal = true
      this.newContactType = type
      this.newContactId = contactId ?? null
      this.infoPrefill = null
      this.resetContactForm = !this.resetContactForm
    },
    closeContactModal() {
      this.showAddContactModal = false
      this.$set(this.activePurchaseOrder, 'acquereur', null)
      this.infoPrefill = null
    },
    updateNewContact(contact: Contact) {
      this.showAddContactModal = false
      this.newContactType = 'acquereur'
      this.newContact = contact
    },
    updateAcquereur(contact: Contact) {
      this.activePurchaseOrder.acquereur = null
      if (contact?.id !== null) {
        this.acquereurs = [
          {
            label: contact?.formName ?? null,
            value: contact?.id?.toString() ?? null,
          },
        ]
        this.$nextTick(() => {
          this.activePurchaseOrder.acquereur = contact ?? null
          this.activePurchaseOrder.plateNumber = null
          this.activePurchaseOrder.acquereurPhoneNumber = contact.mobile ?? contact.phone
          this.acquereurId = contact.id?.toString() ?? null
        })
      }
    },
    getLastAcquereur() {
      return this.acquereurs.find((acquereur) => {
        if (this.activePurchaseOrder.acquereur?.id !== undefined) {
          return acquereur.value === this.activePurchaseOrder.acquereur?.id?.toString()
        } else {
          return acquereur.value === this.activePurchaseOrder.acquereur?.toString()
        }
      })
    },
    acquereurSort(itemA: PurchaseOrder, itemB: PurchaseOrder) {
      const fullNameA = this.formatAcquereurName(itemA, true) ?? ''
      const fullNameB = this.formatAcquereurName(itemB, true) ?? ''
      if (fullNameA == fullNameB) {
        return 0
      }

      return fullNameA < fullNameB ? -1 : 1
    },
    itemNumberSort(itemA: PurchaseOrder, itemB: PurchaseOrder) {
      const itemNumberA = this.convertItemNumber(itemA) ?? 0
      const itemNumberB = this.convertItemNumber(itemB) ?? 0
      if (itemNumberA == itemNumberB) {
        return 0
      }

      return itemNumberA < itemNumberB ? -1 : 1
    },
    convertItemNumber(purchaseOrder: PurchaseOrder) {
      return parseFloat(purchaseOrder.requisitionItem?.fullItemNumber?.replace('-', '.') ?? '')
    },
    checkMustCall() {
      if (
        this.showPurchaseOrderModal &&
        this.activePurchaseOrder.list !== undefined &&
        this.activePurchaseOrder.list !== ''
      ) {
        this.activePurchaseOrder = {
          ...this.activePurchaseOrder,
          mustCall: true,
        }
      }
    },
  },
})
