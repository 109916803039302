
























































import Vue from 'vue'
import { ApiResponse } from '@/interfaces'
import { mapActions } from 'vuex'

export default Vue.extend({
  data() {
    return {
      loginError: false,
      form: {
        email: '',
        password: '',
        twoFactorCode: null,
      },
      rememberMe: false,
      notificationMessage: '',
      show2FAModal: false,
    }
  },
  mounted() {
    if (process.env.NODE_ENV == 'development') {
      this.form = {
        email: 'admin@exeve.test',
        password: 'password',
        twoFactorCode: null,
      }
    }
  },
  methods: {
    ...mapActions(['loadAppData']),
    submit() {
      this.loginError = false
      this.show2FAModal = false

      this.$api
        .post('/login', {
          email: this.form.email,
          password: this.form.password,
          twoFactorCode: this.form.twoFactorCode,
          rememberme: this.rememberMe,
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse
          // Checking for 2FA requirements
          if (apiResponse.data?.twoFactor === true) {
            this.show2FAModal = true
          } else {
            if (apiResponse.success == false) {
              this.loginError = true
              this.notificationMessage =
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.'

              this.form = {
                email: '',
                password: '',
                twoFactorCode: null,
              }

              this.rememberMe = false

              this.show2FAModal = false
            } else {
              // Getting user infos
              this.loadAppData()

              // Set token and redirect to dashboard
              if (this.$cookies.get('authToken')) {
                this.$cookies.remove('authToken')
              }

              let cookieTime = '0'

              if (this.rememberMe) {
                cookieTime = '30d'
              }

              // Setting session cookie
              this.$cookies.set('authToken', apiResponse.data?.token, cookieTime)

              // Redirecting
              if (this.$route.query.returnURL) {
                this.$router.push(`${this.$route.query.returnURL}`)
              } else {
                this.$router.push({ name: 'dashboard' })
              }

              this.show2FAModal = false
            }
          }
        })
        .catch((error) => {
          this.loginError = true
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse
            this.notificationMessage =
              apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.'
          }
        })
        .finally(() => {
          this.form.twoFactorCode = null
        })
    },
  },
})
