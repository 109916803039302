































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import ContactForm from '@/components/ContactForm.vue'
import InvoicesTable from '@/components/InvoicesTable.vue'
import ProjectList from '@/components/Contact/ProjectList.vue'
import BoughtItems from '@/components/Contact/BoughtItems.vue'
import DocumentsList from '@/components/DocumentsList.vue'
import DocumentsUpload from '@/components/DocumentsUpload.vue'
import ContactList from '@/components/ContactList.vue'
import { ApiResponse, Invoice, Contact, Document } from '@/interfaces'
import { mapState } from 'vuex'

interface TabObject {
  _props: Record<string, string>
}

export default Vue.extend({
  components: {
    InvoicesTable,
    Nav,
    ContactForm,
    ProjectList,
    BoughtItems,
    DocumentsList,
    DocumentsUpload,
    ContactList,
  },
  data() {
    return {
      contact: null as Contact | null,
      contactNumber: null as number | null,
      busy: false,
      displayAlert: false,
      userId: '',
      saveContactTrigger: false,
      invoices: [] as Invoice[],
      activeTab: 'infos',
      documents: [] as Document[],
      docToUpdate: null as Document | null,
      itemsTotal: 0,
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user']),
    contactName(): string {
      if (this.contact?.formName) {
        return this.contact?.formName
      } else {
        return ''
      }
    },
    itemsTabLabel(): string {
      return `Lots achetés (${this.itemsTotal})`
    },
  },
  watch: {
    user(newVal) {
      this.addons = newVal.client?.addons ?? 0
    },
  },
  mounted() {
    this.userId = this.$route.params.id
    this.displayAlert = false

    if (this.$route.query.tab) {
      this.activeTab = this.$route.query.tab as string
    }
    this.addons = this.user.client?.addons ?? 0
  },
  methods: {
    updateInvoices(invoices: Invoice[]) {
      this.invoices = invoices
    },
    changeTab(tab: TabObject) {
      if (!this.busy) {
        this.activeTab = tab._props.name ?? 'infos'
        // Here we use pushState as using Vue's router would trigger a page reload
        history.pushState(null, '', `${this.$route.path}?tab=${this.activeTab}`)

        this.triggerSave()
      }
      // plus simple : traiter dans le parent le test de l'active tab
      // plutot que le de passer a l'enfant pour qu'il appelle une fonction du
      // parent pour charger la liste des docs
      switch (this.activeTab) {
        case 'documents':
          this.getDocuments()
          break
      }
    },
    triggerSave() {
      this.saveContactTrigger = !this.saveContactTrigger

      return true
    },
    updateContactInfos(contact: Contact) {
      this.contact = contact
      this.contactNumber = contact.contactNumber ?? null
      this.itemsTotal = contact.boughtItemsTotal ?? 0
    },
    getDocuments() {
      if (this.contact?.id !== null) {
        this.$api(`/contact/${this.contact?.id}/documents`).then((response) => {
          const apiResponse = response.data as ApiResponse
          this.documents = apiResponse.data
        })
      }
    },
    updateDocuments(documents: Document[]) {
      // Les appels sur chaque doc ne sont pas sensé renvoyer une liste mais le doc mis à jour
      // l'appel de refresh s'occupe donc de recharger la liste si besoin.
      // appel d'api depuis le composant parent et transmise à composant documentList
      //this.documents = documents
      this.getDocuments()
      // appelé apres upload version corrigée
      // il faut effacer la ref au doc mis à jour (utilisé par DocumentUpload)
      this.docToUpdate = null
    },
    updateDoc(document: Document) {
      this.docToUpdate = document
      ;(this.$refs.uploadDoc as InstanceType<typeof DocumentsUpload>).addFile()
    },
    goBack() {
      this.triggerSave()
      this.routerBack()
    },
    routerBack() {
      this.$router.back()
    },
  },
})
